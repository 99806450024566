<template>
  <b-container
    id="company-select-slide"
    class="container-fluid"
  >
    <slide-heading
      :heading="currentSlide.heading"
    />

    <div class="slide-description mb-2 justify-content-center">
      <div v-html="currentSlideDescription" />
    </div>

    <template v-if="loaded">
      <h4>
        Businesses Needing Review
      </h4>
      <company-selector
        :companies="scopedCompanies"
        @company-selected="companySelected"
      />
    </template>

    <ct-centered-spinner v-else>
      {{ loadingText }}
    </ct-centered-spinner>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as DOMPurify from 'dompurify'

export default {
  name: 'CompanySelectSlide',
  components: {
    CompanySelector:   () => import('@/components/StagelineV2/shared/CompanySelector'),
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    SlideHeading:      () => import('@/components/StagelineV2/shared/SlideHeading'),
  },
  props: {},
  data() {
    return {
      loaded: false,
      loadingText: 'Loading Companies...',
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'currentSlide',
      'accountCompanies',
    ]),
    scopedCompanies() {
      return this.accountCompanies.filter(c => c?.stageline?.route?.status_key === 'needs_corporate_transparency_act_check')
    },
    companyCount() {
      return this.accountCompanies?.length || 0
    },
    currentSlideDescription() {
      return DOMPurify.sanitize(this.currentSlide.description)
    },
  },
  async mounted() {
    if (!this.accountCompanies.length) {
      await this.getAccountCompanies()
    }
    this.loaded = true
  },
  methods: {
    ...mapActions('companies', [
      'setCurrentCompany',
    ]),
    ...mapActions('stageline', [
      'getAccountCompanies',
    ]),
    async companySelected(selectedCompany) {
      this.loadingText = 'Loading Company...'
      this.loaded = false

      await this.setCurrentCompany({ id: selectedCompany.id, preserveStageline: true })

      this.$emit('next-slide')
    },
  },
}
</script>

<style scoped lang="scss">
#company-select-slide {

}
</style>
